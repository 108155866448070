<template>
    <div class="grid grid-cols-12 gap-4">
        <div class="col-span-3">
            <div class="p-5 bg-white rounded-lg shadow-lg">
                <div v-for="(item, index) in menus" :key="index" class="min-h-[60px] text-base">
                    <router-link v-show="checkPermission(item.val)" class="p-5 py-2 rounded-lg block" :to="{ name: item.val }">
                        {{ item.txt }}
                    </router-link>
                </div>
            </div>
        </div>
        <div class="col-span-9">
            <keep-alive>
                <router-view />
            </keep-alive>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
export default {
    computed: {
        ...mapState("permissionStore", ["isAdmin", "permissionList"]),
        // 取得左側選單權限的路由
        sideMenusPermissionRouters() {
            return this.$route.meta.params.sideItems ? this.$route.meta.params.sideItems : [];
        },
        menus() {
            return [
                {
                    txt: "代付款項 撥款處理",
                    val: "broker_submit_application",
                    show: this.checkPermission("broker_submit_application"),
                },
                {
                    txt: "撥款異常",
                    val: "broker_error_withdraw",
                    show: this.checkPermission("broker_error_withdraw"),
                },
                {
                    txt: "撥款完成紀錄",
                    val: "broker_finsh_record",
                    show: this.checkPermission("broker_finsh_record"),
                },
            ];
        },
    },
    methods: {
        checkPermission(key) {
            // 判斷是 admin 權限不阻擋
            if (this.isAdmin) {
                return true;
            }
            // 判斷登入身份是否有權限
            if (this.sideMenusPermissionRouters.includes(key)) {
                return true;
            }
            // 沒權限時無法看到選單
            return false;
        },
        goFirstTab() {
            this.$nextTick(() => {
                const idx = this.menus.findIndex((i) => i.show === true);
                if (idx !== -1) {
                    this.$router.push({ name: this.menus[idx].val });
                }
            });
        },
    },
    mounted() {
        // 導致有權限的第一頁
        this.goFirstTab();
    },
    activated() {
        // 導致有權限的第一頁
        this.goFirstTab();
    },
};
</script>

<style scoped lang="scss">
.router-link-exact-active {
    @apply bg-red-500 bg-opacity-20 rounded-lg flex items-center;
}
</style>
